import { Link } from 'gatsby';
import './contact-uxology.scss';
import React from 'react';

export const ContactUxology = (props) => (
	<div className={`contact-the-uxology-group ${props.className}`}>
		<Link to="/contact/" state={{ page: props.page }}>
			<strong>{props.topLabel}</strong>
			{props.withSameSize ? (
				<strong>{props.bottomLabel}</strong>
			) : (
				<span>{props.bottomLabel}</span>
			)}
		</Link>
	</div>
);
